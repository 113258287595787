/**
 * JSON schema definitions for image presets.
 * 
 * Used for input validation in the ForgeImageProcessingLambda and ForgeToolWebsite packages.
 */

import { JSONSchemaType } from 'ajv';
import * as Presets from '../presets';

const Blur: JSONSchemaType<Presets.Blur> = {
    type: 'object',
    properties: {
        type: { type: 'string', const: Presets.OperationType.Blur },
        blur: { type: 'number', exclusiveMinimum: 0 },
    },
    required: ['type', 'blur'],
};

const Brightness: JSONSchemaType<Presets.Brightness> = {
    type: 'object',
    properties: {
        type: { type: 'string', const: Presets.OperationType.Brightness },
        brightness: { type: 'number', minimum: -100, maximum: 100 },
    },
    required: ['type', 'brightness'],
};

const Circle: JSONSchemaType<Presets.Circle> = {
    type: 'object',
    properties: {
        type: { type: 'string', const: Presets.OperationType.Circle },
        units: {
            type: 'string',
            enum: [Presets.UnitType.Pixels, Presets.UnitType.Percentage],
        },
        radius: { type: 'number' },
        offsetX: { type: 'number' },
        offsetY: { type: 'number' },
    },
    required: ['type', 'units', 'radius', 'offsetX', 'offsetY'],
};

const Opacity: JSONSchemaType<Presets.Opacity> = {
    type: 'object',
    properties: {
        type: { type: 'string', const: Presets.OperationType.Opacity },
        opacity: { type: 'number', minimum: 0, maximum: 100 },
    },
    required: ['type', 'opacity'],
};

const Print: JSONSchemaType<Presets.Print> = {
    type: 'object',
    properties: {
        type: { type: 'string', const: Presets.OperationType.Print },
        font: { type: 'string' },
        text: { type: 'string' },
        offsetX: { type: 'number' },
        offsetY: { type: 'number' },
        width: { type: 'number', nullable: true },
        height: { type: 'number', nullable: true },
        horizontal: {
            nullable: true,
            type: 'string',
            enum: [
                Presets.HorizontalAlignment.Left,
                Presets.HorizontalAlignment.Center,
                Presets.HorizontalAlignment.Right,
            ],
        },
        vertical: {
            nullable: true,
            type: 'string',
            enum: [
                Presets.VerticalAlignment.Top,
                Presets.VerticalAlignment.Middle,
                Presets.VerticalAlignment.Bottom,
            ],
        },
    },
    required: ['type', 'font', 'text', 'offsetX', 'offsetY']
}

const Resize: JSONSchemaType<Presets.Resize> = {
    type: 'object',
    properties: {
        type: { type: 'string', const: Presets.OperationType.Resize },
        style: {
            type: 'string',
            enum: [
                Presets.ResizeStyle.Contain,
                Presets.ResizeStyle.Cover,
                Presets.ResizeStyle.Stretch,
            ],
        },
        height: { type: 'integer', exclusiveMinimum: 0 },
        width: { type: 'integer', exclusiveMinimum: 0 },
        horizontal: {
            nullable: true,
            type: 'string',
            enum: [
                Presets.HorizontalAlignment.Left,
                Presets.HorizontalAlignment.Center,
                Presets.HorizontalAlignment.Right,
            ],
        },
        vertical: {
            nullable: true,
            type: 'string',
            enum: [
                Presets.VerticalAlignment.Top,
                Presets.VerticalAlignment.Middle,
                Presets.VerticalAlignment.Bottom,
            ],
        },
    },
    required: ['type', 'style', 'height', 'width'],
};

const Rotate: JSONSchemaType<Presets.Rotate> = {
    type: 'object',
    properties: {
        type: { type: 'string', const: Presets.OperationType.Rotate },
        degrees: { type: 'number', exclusiveMinimum: 0, exclusiveMaximum: 360 },
        resize: { type: 'boolean' },
    },
    required: ['type', 'degrees', 'resize'],
};

export const Preset: JSONSchemaType<Presets.Preset> = {
    type: 'object',
    properties: {
        layers: {
            type: 'array',
            items: {
                minItems: 1,
                type: 'object',
                properties: {
                    sourceId: { type: 'string' },
                    operations: {
                        type: 'array',
                        items: {
                            type: 'object',
                            oneOf: [Blur, Brightness, Circle, Opacity, Print, Resize, Rotate],
                            required: ['type'],
                        },
                    },
                    overlay: {
                        nullable: true,
                        type: 'object',
                        properties: {
                            offsetX: { type: 'number' },
                            offsetY: { type: 'number' },
                        },
                        required: ['offsetX', 'offsetY'],
                    },
                },
                required: ['sourceId', 'operations'],
            },
        },
        output: {
            type: 'object',
            nullable: true,
            properties: {
                mime: {
                    type: 'string',
                    nullable: true,
                    enum: [Presets.OutputMime.BMP, Presets.OutputMime.JPEG, Presets.OutputMime.PNG]
                },
                quality: { type: 'number', nullable: true }
            }
        }
    },
    required: ['layers'],
};
