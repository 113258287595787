/**
 * In-process implementation of the image processor.
 *
 * Uses the image editing library JIMP to apply a preset to one or more source images. Both the
 * ForgeImageProcessingLambda and ForgeToolWebsite packages use this functionality to perform
 * image processing in-process.
 */

import {
    Circle,
    UnitType,
    Resize,
    VerticalAlignment,
    HorizontalAlignment,
    ResizeStyle,
    Rotate,
    ImageLayer,
    OperationType,
    Overlay,
    Preset,
    Print,
} from './presets';
import Jimp from 'jimp';

function getVerticalAlignment(option?: VerticalAlignment, def: number = 0): number {
    switch (option) {
        case VerticalAlignment.Bottom:
            return Jimp.VERTICAL_ALIGN_BOTTOM;
        case VerticalAlignment.Middle:
            return Jimp.VERTICAL_ALIGN_MIDDLE;
        case VerticalAlignment.Top:
            return Jimp.VERTICAL_ALIGN_TOP;
    }
    return def;
}

function getHorizontalAlignment(option?: HorizontalAlignment, def: number = 0): number {
    switch (option) {
        case HorizontalAlignment.Left:
            return Jimp.HORIZONTAL_ALIGN_LEFT;
        case HorizontalAlignment.Center:
            return Jimp.HORIZONTAL_ALIGN_CENTER;
        case HorizontalAlignment.Right:
            return Jimp.HORIZONTAL_ALIGN_RIGHT;
    }
    return def;
}

function circle(src: Jimp, options: Circle) {
    let radius = options.radius;
    let x = options.offsetX;
    let y = options.offsetY;
    if (options.units == UnitType.Percentage) {
        const maxSide = Math.max(src.getWidth(), src.getHeight());
        radius = (radius * maxSide) / 100;
        x = (x * src.getWidth()) / 100;
        y = (y * src.getHeight()) / 100;
    }
    src.circle({ radius, x, y });
}

function resize(src: Jimp, options: Resize) {
    let alignBits =
        getVerticalAlignment(options.vertical) + getHorizontalAlignment(options.horizontal);
    switch (options.style) {
        case ResizeStyle.Contain:
            src.contain(options.width, options.height, alignBits);
            break;
        case ResizeStyle.Cover:
            src.cover(options.width, options.height, alignBits);
            break;
        case ResizeStyle.Stretch:
            src.resize(options.width, options.height);
            break;
    }
}

function rotate(src: Jimp, options: Rotate) {
    src.rotate(options.degrees, options.resize);
}

async function print(src: Jimp, options: Print, strings: Record<string, string>): Promise<void> {
    console.log(`Loading font: ${options.font}`);
    const font = await Jimp.loadFont(options.font);
    let text = options.text;
    if (text.startsWith('@str:')) {
        text = strings[text.substring(5)];
        if (!text) {
            throw new Error(`Replacement string ${options.text} not found`);
        }
    }
    src.print(
        font,
        options.offsetX,
        options.offsetY,
        {
            text: text,
            alignmentX: getHorizontalAlignment(options.horizontal, Jimp.HORIZONTAL_ALIGN_LEFT),
            alignmentY: getVerticalAlignment(options.vertical, Jimp.VERTICAL_ALIGN_TOP),
        },
        options.width,
        options.height,
    );
}

async function formatLayer(src: Jimp, layer: ImageLayer, strings: Record<string, string>): Promise<Jimp> {
    const result = src.clone();
    for (let index = 0; index < layer.operations.length; index++) {
        const options = layer.operations[index];
        switch (options.type) {
            case OperationType.Blur:
                result.blur(options.blur);
                break;
            case OperationType.Brightness:
                result.brightness(options.brightness / 100);
                break;
            case OperationType.Opacity:
                result.opacity(options.opacity / 100);
                break;
            case OperationType.Circle:
                circle(result, options);
                break;
            case OperationType.Resize:
                resize(result, options);
                break;
            case OperationType.Rotate:
                rotate(result, options);
                break;
            case OperationType.Print:
                await print(result, options, strings);
        }
    }
    return result;
}

function composeLayers(src: Jimp, dest?: Jimp, options?: Overlay): Jimp {
    if (!dest) {
        return src;
    }
    return dest.composite(src, options?.offsetX || 0, options?.offsetY || 0);
}

export interface FormatInputs {
    images: Record<string, Buffer>;
    strings?: Record<string, string>;
}

export interface FormattedImage {
    buffer: Buffer;
    mime: string;
    fileExtension: string;
    heightInPixels: number;
    widthInPixels: number;
}

export async function applyPreset(inputs: FormatInputs, preset: Preset): Promise<FormattedImage> {
    let result: Jimp | undefined;
    const images: Record<string, Jimp> = {};
    const promises = Object.keys(inputs.images).map(
        async (key) => (images[key] = await Jimp.read(inputs.images[key])),
    );
    await Promise.all(promises);
    for (let index = 0; index < preset.layers.length; index++) {
        const layer = preset.layers[index];
        const image = images[layer.sourceId];
        if (!image) {
            throw new Error(`Source image for layer ${index} with id ${layer.sourceId} not found`);
        }
        const src = await formatLayer(image, layer, inputs.strings ?? {});
        result = composeLayers(src, result, layer.overlay);
    }
    if (!result) {
        throw new Error('Specified preset did not generate any output');
    }
    if (preset.output?.quality) {
        result.quality(preset.output.quality);
    }
    const mime = preset.output?.mime ?? result.getMIME();
    return {
        buffer: await result.getBufferAsync(mime),
        mime,
        fileExtension: result.getExtension(),
        heightInPixels: result.getHeight(),
        widthInPixels: result.getWidth(),
    };
}
