/**
 * Type definitions for image presets.
 *
 * A preset is the configuration provided to the image processor which describes one or more layers
 * that make up the final image. Each layer specifies a source and one or more operations performed
 * to that source.
 */

import { fonts as Fonts } from './fonts';

export interface Preset {
    layers: ImageLayer[];
    output?: Output;
}

export enum OutputMime {
    PNG = 'image/png',
    JPEG = 'image/jpeg',
    BMP = 'image/bmp',
}

export interface Output {
    mime?: OutputMime;
    quality?: number;
}

export interface ImageLayer {
    sourceId: string;
    operations: Operation[];
    overlay?: Overlay;
}

export interface Overlay {
    offsetX: number;
    offsetY: number;
}

export enum UnitType {
    Pixels = 'pixels',
    Percentage = 'percentage',
}

export enum VerticalAlignment {
    Top = 'top',
    Middle = 'middle',
    Bottom = 'bottom',
}

export enum HorizontalAlignment {
    Left = 'left',
    Center = 'center',
    Right = 'right',
}

export enum OperationType {
    Blur = 'blur',
    Brightness = 'brightness',
    Circle = 'circle',
    Opacity = 'opacity',
    Print = 'print',
    Resize = 'resize',
    Rotate = 'rotate',
}

export type Operation = Blur | Brightness | Circle | Opacity | Print | Resize | Rotate;

export interface Blur {
    type: OperationType.Blur;
    blur: number;
}

export interface Brightness {
    type: OperationType.Brightness;
    brightness: number;
}

export interface Circle {
    type: OperationType.Circle;
    units: UnitType;
    radius: number;
    offsetX: number;
    offsetY: number;
}

export interface Opacity {
    type: OperationType.Opacity;
    opacity: number;
}

export interface Print {
    type: OperationType.Print;
    font: string;
    text: string;
    offsetX: number;
    offsetY: number;
    width?: number;
    height?: number;
    horizontal?: HorizontalAlignment;
    vertical?: VerticalAlignment;
}

export enum ResizeStyle {
    Cover = 'cover',
    Contain = 'contain',
    Stretch = 'stretch',
}

export interface Resize {
    type: OperationType.Resize;
    style: ResizeStyle;
    height: number;
    width: number;
    vertical?: VerticalAlignment;
    horizontal?: HorizontalAlignment;
}

export interface Rotate {
    type: OperationType.Rotate;
    degrees: number;
    resize: boolean;
}

const DefaultArtistResize: Resize = {
    type: OperationType.Resize,
    style: ResizeStyle.Cover,
    height: 1100,
    width: 1100,
    vertical: VerticalAlignment.Middle,
    horizontal: HorizontalAlignment.Center,
};

const DefaultArtistCircle: Circle = {
    type: OperationType.Circle,
    units: UnitType.Percentage,
    radius: 50,
    offsetX: 50,
    offsetY: 50,
};

// TODO: Move the pre-defined presets to DDB
// SIM: https://sim.amazon.com/issues/AMMIX-2842

export const DefaultSpotlightLandingPreset: Preset = {
    layers: [
        {
            sourceId: 'artist',
            operations: [
                {
                    ...DefaultArtistResize,
                    width: 500,
                    height: 500,
                } as Resize,
            ],
        },
    ],
    output: {
        mime: OutputMime.JPEG,
        quality: 96,
    },
};

export const DefaultSpotlightInterludePreset: Preset = {
    layers: [
        {
            sourceId: 'artist',
            operations: [DefaultArtistResize],
        },
    ],
    output: {
        mime: OutputMime.JPEG,
        quality: 96,
    },
};

export const DefaultSpotlightSocialPreset: Preset = {
    layers: [
        {
            sourceId: 'background',
            operations: [
                {
                    type: OperationType.Brightness,
                    brightness: -80,
                } as Brightness,
                {
                    type: OperationType.Resize,
                    width: 1200,
                    height: 630,
                    style: ResizeStyle.Cover,
                    vertical: VerticalAlignment.Top,
                    horizontal: HorizontalAlignment.Center,
                } as Resize,
                {
                    type: OperationType.Print,
                    font: Fonts.FONT_AMAZONEMBERDISPLAYCONDENSED_HEAVY_100_WHITE,
                    text: '@str:artist_name',
                    offsetX: 0,
                    offsetY: 436,
                    width: 1200,
                    height: 80,
                    vertical: VerticalAlignment.Top,
                    horizontal: HorizontalAlignment.Center,
                } as Print,
                {
                    type: OperationType.Print,
                    font: Fonts.FONT_AMAZONEMBER_BOLD_32_WHITE,
                    text: 'SPOTLIGHT',
                    offsetX: 0,
                    offsetY: 568,
                    width: 1200,
                    height: 30,
                    vertical: VerticalAlignment.Top,
                    horizontal: HorizontalAlignment.Center,
                } as Print,
            ],
        },
        {
            sourceId: 'logo',
            operations: [
                {
                    type: OperationType.Resize,
                    width: 210,
                    height: 42,
                    style: ResizeStyle.Contain,
                } as Resize,
            ],
            overlay: {
                offsetX: 963,
                offsetY: 560,
            },
        },
        {
            sourceId: 'artist',
            operations: [
                {
                    ...DefaultArtistResize,
                    width: 346,
                    height: 346,
                },
                DefaultArtistCircle,
            ],
            overlay: {
                offsetX: 428,
                offsetY: 40,
            },
        },
    ],
    output: {
        mime: OutputMime.JPEG,
        quality: 96,
    },
};

export const DefaultArtistPreset: Preset = {
    layers: [
        {
            sourceId: 'default',
            operations: [
                DefaultArtistResize,
                {
                    type: OperationType.Blur,
                    blur: 30,
                } as Blur,
                {
                    type: OperationType.Brightness,
                    brightness: -50,
                } as Brightness,
            ],
        },
        {
            sourceId: 'default',
            operations: [
                DefaultArtistResize,
                {
                    type: OperationType.Blur,
                    blur: 30,
                } as Blur,
                {
                    type: OperationType.Brightness,
                    brightness: -25,
                } as Brightness,
                {
                    type: OperationType.Circle,
                    units: UnitType.Percentage,
                    radius: 75,
                    offsetX: 65,
                    offsetY: 35,
                } as Circle,
            ],
        },
        {
            sourceId: 'default',
            operations: [
                DefaultArtistResize,
                {
                    type: OperationType.Blur,
                    blur: 30,
                } as Blur,
                {
                    type: OperationType.Brightness,
                    brightness: -40,
                } as Brightness,
                {
                    type: OperationType.Circle,
                    units: UnitType.Percentage,
                    radius: 60,
                    offsetX: 65,
                    offsetY: 35,
                } as Circle,
            ],
        },
        {
            sourceId: 'default',
            overlay: {
                offsetX: 250,
                offsetY: -50,
            },
            operations: [
                {
                    ...DefaultArtistResize,
                    height: 900,
                    width: 900,
                } as Resize,
                DefaultArtistCircle,
            ],
        },
    ],
};

export const DefaultPresets: Record<string, Preset> = {
    DEFAULT_ARTIST_IMAGE: DefaultArtistPreset,
    DEFAULT_SPOTLIGHT_SOCIAL: DefaultSpotlightSocialPreset,
    DEFAULT_SPOTLIGHT_LANDING: DefaultSpotlightLandingPreset,
    DEFAULT_SPOTLIGHT_INTERLUDE: DefaultSpotlightInterludePreset,
};
