/**
 * JSON schema definitions for service operations.
 * 
 * Used for input validation in the ForgeImageProcessingLambda package.
 */

import { JSONSchemaType } from 'ajv';
import * as Operations from '../operations';
import { Preset } from './presets';

export const FormatRequest: JSONSchemaType<Operations.FormatRequest> = {
    type: 'object',
    properties: {
        sources: {
            type: 'array',
            minItems: 1,
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'string',
                    },
                    uri: {
                        type: 'string',
                    },
                },
                required: ['id', 'uri'],
            },
        },
        strings: {
            type: 'array',
            minItems: 0,
            nullable: true,
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'string',
                    },
                    text: {
                        type: 'string',
                    },
                },
                required: ['id', 'text'],
            },
        },
        format: {
            type: 'object',
            oneOf: [
                {
                    type: 'object',
                    properties: {
                        preset: {
                            type: 'string',
                        },
                    },
                    required: ['preset'],
                },
                {
                    type: 'object',
                    properties: {
                        inline: {
                            ...Preset,
                        },
                    },
                    required: ['inline'],
                },
            ],
        },
        output: {
            type: 'object',
            properties: {
                s3: {
                    type: 'object',
                    oneOf: [
                        {
                            properties: {
                                bucket: { type: 'string' },
                                key: { type: 'string' },
                                region: { type: 'string', nullable: true },
                            },
                            required: ['bucket', 'key'],
                        },
                        {
                            properties: {
                                signedUrl: { type: 'string' }
                            },
                            required: ['signedUrl'],
                        }
                    ],
                    required: []
                },
            },
            required: ['s3'],
        },
    },
    required: ['sources', 'format', 'output'],
};
