/**
 * List of fonts embedded in this package that can be used as part of image formatting.
 *
 * The fonts below are bitmap fonts (https://en.wikipedia.org/wiki/Bitmap_fonts) generated from
 * Amazon's publicly available Ember font files
 * (https://developer.amazon.com/en-US/alexa/branding/echo-guidelines/identity-guidelines/typography)
 */
import Path from 'path';

const dir = process.env.DIRNAME || `${__dirname}/../`;

export const fonts = {
    FONT_AMAZONEMBER_BOLD_32_WHITE: Path.join(
        dir,
        'fonts/AmazonEmber_Bd_32_White/AmazonEmber_Bd_32_White.ttf.fnt',
    ),
    FONT_AMAZONEMBERDISPLAY_HEAVY_100_WHITE: Path.join(
        dir,
        'fonts/AmazonEmberDisplay_He_100_White/AmazonEmberDisplay_He_100_White.ttf.fnt',
    ),
    FONT_AMAZONEMBERDISPLAYCONDENSED_HEAVY_100_WHITE: Path.join(
        dir,
        'fonts/AmazonEmberDisplayCondensed_He_100_White/AmazonEmberDisplayCondensed_He_100_White.ttf.fnt',
    ),
} as const;
